<div class="row pointer" *ngIf="post">
  <!--  le media est une image-->
  <div class="col post-center" style="height: {{height}}px;" *ngIf="isImage()">
    <img src="{{post.thumbnail}}" class="m-r-5 skillsmates-post-image" alt="" (click)="onClickMedia(post)">
    <!--    <sm-image-->
    <!--      (click)="onClickMedia(post)"-->
    <!--      class="m-r-5 skillsmates-post-image"-->
    <!--      image="{{post.thumbnail}}"-->
    <!--      default="{{'publications' | image}}">-->
    <!--    </sm-image>-->
  </div>

  <div class="image-container" *ngIf="isYoutube()" (click)="onClickMedia(post)">
    <div class="overlay-image">
      <img class="img-fluid" style="" src="{{ 'youtube' | image }}" alt="Youtube logo"/>
    </div>
    <img  [src]="'https://img.youtube.com/vi/'+getYoutubeVideoId()+'/sddefault.jpg'"  alt="Background Image" class="background-image">
    <!--    <sm-image-->
    <!--      (click)="onClickMedia(post)"-->
    <!--      class="background-image"-->
    <!--      image="{{post.thumbnail}}"-->
    <!--      default="{{'publications' | image}}">-->
    <!--    </sm-image>-->
  </div>

  <div class="image-container" *ngIf="isSpotify()">
    <div class="overlay-image" (click)="onClickMediaLink(post)">
      <img class="img-fluid" style="" src="{{ 'spotify' | image }}" alt="audio logo" />
    </div>
    <img  [src]="post.thumbnail"  alt="Background Image" class="background-image" (click)="onClickMediaLink(post)">
  </div>

  <!--  le media est un lien-->
  <div class="col post-center" style="height: {{height}}px;" *ngIf="isLink() && !isYoutube() && !isSpotify()">
    <img src="{{post.thumbnail}}" class="m-r-5 skillsmates-post-image" alt="" (click)="onClickMediaLink(post)">
    <!--    <sm-image-->
    <!--      (click)="onClickMediaLink(post)"-->
    <!--      class="m-r-5 skillsmates-post-image"-->
    <!--      image="{{post.thumbnail}}"-->
    <!--      default="{{'publications' | image}}">-->
    <!--    </sm-image>-->
  </div>

  <!--  Le media est un pdf-->
  <div class="col" *ngIf="isPdf()">
    <pdf-viewer
      (click)="onClickMedia(post)"
      [src]="post.thumbnail!"
      [page]="2"
      [rotation]="0"
      [original-size]="false"
      [show-all]="true"
      [fit-to-page]="true"
      [zoom]="1"
      [zoom-scale]="'page-width'"
      [stick-to-page]="false"
      [render-text]="true"
      [external-link-target]="'blank'"
      [autoresize]="true"
      [show-borders]="false"
      style="width: 100%; height: {{height}}px;">
    </pdf-viewer>
  </div>

  <div class="col pointer" *ngIf="isOfficeDocument()">
    <ngx-doc-viewer
      (click)="onClickMedia(post)"
      [url]="post.link!"
      viewer="google"
      style="width:100%;height:{{height}}px;">
    </ngx-doc-viewer>
  </div>
  <div class="col pointer" *ngIf="isVideoFile()">
    <video width="100%" controls (click)="onClickMedia(post)">
      <source [src]="post.link!" >
    </video>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"><b>{{ post.title }}</b></h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
  </div>
  <div class="modal-body thumb">
    <ngx-extended-pdf-viewer *ngIf="isPdf()" [src]="post.thumbnail!"></ngx-extended-pdf-viewer>
    <img *ngIf="isImage()" src="{{post.thumbnail}}" class="m-r-5 skillsmates-post-image" alt="" (error)="setDefault()"/>
    <!-- <sm-image
      *ngIf="isImage()"
      [class]="''"
      image="{{post.thumbnail}}"
      default="{{'publications' | image}}">
    </sm-image>  -->
    <youtube-player [width]="window.innerWidth<700?320:700" *ngIf="isYoutube()"
                    [videoId]="getYoutubeVideoId()!"></youtube-player>
    <ngx-doc-viewer *ngIf="isOfficeDocument()" [url]="post.link!" viewer="google"></ngx-doc-viewer>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="d('Cross click')">Fermer</button>
  </div>
</ng-template>
