<div class="row" style="padding-bottom: 5px; padding-top: 5px;">
  <div class="col-2">
    <span class="user-img">
      <a *ngIf="forum.account.uuid === loggedAccount.uuid" [routerLink]="[]">
        <!--<img class="rounded-circle" src="{{forum.account | avatar}}" style="background-color: whitesmoke;" width="36"
             alt="{{forum.account.lastname}}">-->
        <span class="user-img">
      <a *ngIf="forum.account" style="cursor: pointer;">
        <img (error)="forum.account.avatar='./assets/images/user.svg'" *ngIf="forum.account.avatar != undefined"
             alt="{{forum.account.lastname}}"
             class="rounded-circle" height="35" src="{{forum.account.avatar}}"
             style="background-color: whitesmoke;"
             width="35">
      </a>
      <span *ngIf="forum.account?.connected" class="status online"></span>
      <span *ngIf="!forum.account?.connected" class="status offline"></span>
    </span>
      </a>
      <a *ngIf="forum.account.uuid !== loggedAccount.uuid"
         [routerLink]="[]">
        <img class="rounded-circle" src="{{forum.account | avatar}}" style="background-color: whitesmoke;" width="36"
             alt="{{forum.account.lastname}}">
      </a>
      <span *ngIf="forum.account.connected" class="status online"></span>
      <span *ngIf="!forum.account.connected" class="status offline"></span>
    </span>
  </div>
  <div class="col-10 text-left">
    <div class="text">
      <strong>{{name(forum.account)}}</strong>
    </div>
    <div class="date-post" style="font-style: italic; font-size: 10px;">
      {{forum.account.createdAt | timeAgo}}
    </div>
  </div>
</div>

<div class="row" style="padding-bottom: 5px;">
  <div class="col-12 skillsmates-two-lines text-left">
    {{forum.description}}
  </div>
</div>

<div class="row" style="padding-bottom: 5px; font-size: 10px;">
  <div class="col-7 text-left">
    <strong>Discipline:</strong> {{forum.discipline}}
  </div>
  <div class="col-5 text-right">
    <span>
      <img width="18" src="{{'comment-active' | image}}" class="m-r-5 svg" alt="comment"> {{forum.nb}} réponses
    </span>
  </div>
</div>
<sm-horizontal-line *ngIf="!isLastOne" [color]="'#DADADA'"></sm-horizontal-line>
