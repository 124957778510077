<div class="skillsmates-box-shadow card" style="padding-bottom: 5px;">
  <div class="row card-header skillsmates-box-top" style="margin: 0 1px 0 1px; cursor: pointer;"
       (click)="expandOrHide()">
    <div class="col-md-12 no-margin-padding  collapsed d-block" data-toggle="collapse" href="#collapse-collapsed"
         aria-expanded="true" aria-controls="collapse-collapsed" id="heading-collapsed">
      <em class="fa fa-chevron-down pull-right" style="padding-top: 10px; padding-left: 5px;"></em>
      <img height="24" src="{{'library' | image}}" alt=""> &nbsp;
      <strong>E-LEARNING</strong> ({{ posts.length }})
    </div>
  </div>
  <div class="row bg-white" style="padding: 0 0 10px 0; margin: 0; border-radius: 0 0 10px 10px;" *ngIf="expand &&
   posts.length > 0">
    <div class="col">
      <carousel style="height: 400px; z-index: -0;">
        <div class="carousel-cell col-6 col-sm-6 col-md-4 col-lg-3" style="padding: 2px;" *ngFor="let post of posts">
          <sm-search-post [post]="post" (click)="onClickPost(post)"></sm-search-post>
        </div>
      </carousel>
    </div>
  </div>
</div>
