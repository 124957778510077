import {Component, Input, OnInit} from '@angular/core';
import {PartnerData} from "../../models/partnerData";

@Component({
  selector: 'sm-partner-view',
  templateUrl: './partner-view.component.html',
  styleUrls: ['./partner-view.component.scss']
})
export class PartnerViewComponent implements OnInit {

  @Input() partnerData: PartnerData = {} as PartnerData;

  ngOnInit() {
  }

  onClickOpenPartner(url: string): void {
    window.open(url, '_blank');
  }

}
