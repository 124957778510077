export class PartnerData {
  image!: string
  url!: string


  constructor(image: string, url: string) {
    this.image = image;
    this.url = url;
  }

  getPartnerPicture() {
    return './assets/images/' + this.image
  }
}
