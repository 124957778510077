<div class="row skillsmates-container">

  <div class="col">
    <sm-forum></sm-forum>
    <sm-selections></sm-selections>
    <div class="">
      <sm-ambassadors></sm-ambassadors>
      <sm-partners></sm-partners>
    </div>

    <div (click)="scrollToTop()" class="scroll-to-top">
      <button class="btn"><em class="fa fa-arrow-up"></em></button>
    </div>
  </div>

</div>
