<div class="skillsmates-box-shadow" [ngStyle]="{
      'background-image': 'url('+ quote.getAuthorPicture()+')',
      'height': '280px',
      'display':'flex',
      'border-radius': '10px',
      'flex-direction':'column',
      'background-size': 'cover',
      'background-position': 'center',
      'background-repeat': 'no-repeat'
    }">
  <div style="flex: 1; width: 100%; object-fit: cover; background-color: rgba(0, 0, 0, 0.41);
      border-top-left-radius: 10px; border-top-right-radius: 10px;"></div>
  <div style="display: flex; flex-direction: column; justify-content: flex-end; background-color: rgba(0, 0, 0, 0.41);
    border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
    <div class="row text-center" style="color: whitesmoke; font-size: large; font-family: 'cursive' ;
      padding-left: 10px; padding-right:10px;" [innerText]="quote.quote"></div>
    <div class="row text-center" style="color: whitesmoke; font-size: medium; font-family: 'Comic Sans MS', cursive;
       font-weight: bold; margin-left: auto; margin-right: auto; margin-top: 15px" [innerText]="quote.authorName"></div>
  </div>
</div>
