import {Component, OnInit} from '@angular/core';
import {SmSelection} from "../../models/smSelection";
import {AccountDetailsResource} from "ApiModuleAccount";
import {PostDetailsResource, PostService} from "ApiModulePost";
import {variables} from "../../../environments/variables";
import {LocalStorageService} from "../../services/local-storage.service";
import {Router} from "@angular/router";
import {Route} from "../../enums/route.enum";

@Component({
  selector: 'sm-selections',
  templateUrl: './selections.component.html',
  styleUrls: ['./selections.component.scss']
})
export class SelectionsComponent implements OnInit {

  accounts: AccountDetailsResource[] = [];
  selections: SmSelection[] = [];
  posts: PostDetailsResource[] = []
  expand: boolean = false

  constructor(private postService: PostService, private storageService: LocalStorageService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.initSelections();

    this.postService.getTopActivePosts(10, 10).subscribe({
      next: response => {
        this.posts = response.resources!
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  expandOrHide(): void {
    this.expand = !this.expand;
  }

  onClickPost(post: PostDetailsResource): void {
    if (!this.storageService.isOffline()) {
      this.storageService.add(variables.post_details, post);
      this.storageService.add(variables.previous_route, Route.DASHBOARD);
      this.router.navigate([Route.POST]);
    }
  }

  initSelections(): void {
    let selection: SmSelection = {} as SmSelection;
    selection.account = {} as AccountDetailsResource;
    selection.description = 'Optimisation du rangement par la methode des 5S';
    selection.image = 'pdf';
    selection.liked = 12;
    selection.shared = 28;
    selection.comments = 87;
    selection.downloads = 6;
    this.selections.push(selection);

    selection = {} as SmSelection;
    selection.account = {} as AccountDetailsResource;
    selection.description = 'Pourquoi Bill Gates soutient-il la cryptomonnaie XRP Ripple?';
    selection.image = 'video-rouge';
    selection.liked = 6;
    selection.shared = 45;
    selection.comments = 12;
    selection.downloads = 8;
    this.selections.push(selection);

    selection = {} as SmSelection;
    selection.account = {} as AccountDetailsResource;
    selection.description = 'Illustration du theoreme de Pythagore';
    selection.image = 'photo-vert';
    selection.liked = 7;
    selection.shared = 75;
    selection.comments = 63;
    selection.downloads = 9;
    this.selections.push(selection);
  }
}
