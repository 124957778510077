export class AuthorQuote {
  authorName!: string
  authorPicture!: string
  quote!: string

  constructor(authorName: string, authorPicture: string, quote: string) {
    this.authorName = authorName;
    this.authorPicture = authorPicture;
    this.quote = quote;
  }

  getAuthorPicture() {
    return './assets/images/' + this.authorPicture
  }
}
