import {Component, OnInit} from '@angular/core';
import {AuthorQuote} from "../../models/authorQuote";

@Component({
  selector: 'sm-ambassadors',
  templateUrl: './ambassadors.component.html',
  styleUrls: ['./ambassadors.component.scss']
})
export class AmbassadorsComponent implements OnInit {

  expand: boolean = true
  quotes: AuthorQuote[] = []

  constructor() {
  }

  ngOnInit(): void {
    this.initQuotes()
  }

  expandOrHide(): void {
    this.expand = !this.expand;
  }

  initQuotes() {
    this.quotes.push(new AuthorQuote('Nelson Mandela', 'mandela.jpeg', "L'éducation est l'arme la plus puissante" +
      " qu'on puisse utiliser pour changer le monde"))
    this.quotes.push(new AuthorQuote('Gandhi', 'gandhi.jpg', "La véritable éducation consiste à tirer le meilleur de" +
      " soi-même."))
    this.quotes.push(new AuthorQuote('Martin Luther King', 'martin.jpg', "La fonction de l'éducation est d'apprendre" +
      " à penser intensément et à penser de manière critique. L'intelligence plus le caractère c'est le but de la" +
      " véritable éducation"))
    this.quotes.push(new AuthorQuote('Edward James Olmos', 'edward.jpg', "L'éducation est le seul vaccin contre la" +
      " violence"))
    this.quotes.push(new AuthorQuote('Noam Chomsky', 'noam.jpg', 'La véritable éducation consiste à pousser les gens' +
      ' à penser par eux-même.'))
  }

  onClickOpenAmbassador(url: string): void {
    window.open(url, '_blank');
  }
}
