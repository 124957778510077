<div class="skillsmates-box-shadow card" style="padding-bottom: 5px;">
  <div class="row card-header skillsmates-box-top" style="margin: 0 1px 0 1px; cursor: pointer;"
       (click)="expandOrHide()">
    <div class="col-md-12 no-margin-padding  collapsed d-block" data-toggle="collapse" href="#collapse-collapsed"
         aria-expanded="true" aria-controls="collapse-collapsed" id="heading-collapsed">
      <em class="fa fa-chevron-down pull-right" style="padding-top: 10px; padding-left: 5px;"></em>
      <img height="24" src="{{'ambassador' | image}}" alt=""> &nbsp;
      <strong>Ambassadeurs</strong>
    </div>
  </div>
  <div class="row bg-white" style="padding: 10px 0 10px 0; margin: 0; border-radius: 0 0 10px 10px;" *ngIf="expand">
    <div class="col">
      <carousel style="height: 290px; z-index: -0;">
        <div class="carousel-cell col-6 col-sm-6 col-md-4 col-lg-3" style="padding: 3px;" *ngFor="let quote of quotes">
          <sm-quote [quote]="quote"></sm-quote>
        </div>
      </carousel>
    </div>
  </div>
</div>
