import {Component, OnInit} from '@angular/core';
import {PartnerData} from "../../models/partnerData";

@Component({
  selector: 'sm-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  expand:boolean = true
  partners : PartnerData [] = []

  constructor() {
  }

  ngOnInit(): void {
    this.initPartners()
  }

  initPartners(){
    this.partners.push(new PartnerData("essdyjewel.jpeg", "http://www.essdyjewel.com"))
    this.partners.push(new PartnerData("reburnfit2.jpeg", "http://www.reburnfit2.com"))
    this.partners.push(new PartnerData("skillsmates1.jpeg", "http://www.skills-mates.com"))
    this.partners.push(new PartnerData("skillsmates2.jpeg", "http://www.skills-mates.com"))
    this.partners.push(new PartnerData("skillsmates3.jpeg", "http://www.skills-mates.com"))
  }

  expandOrHide(): void {
    this.expand = !this.expand;
  }

  icon(){
    return this.expand ? 'fa fa-chevron-down pull-right' : 'fa fa-chevron-down pull-right'
  }

  onClickOpenPartner(url: string): void {
    window.open(url, '_blank');
  }
}
