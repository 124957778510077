import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './public/login/login.component';
import {InputComponent} from './components/input/input.component';
import {FormsModule} from "@angular/forms";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {ImagePipe} from './pipes/image.pipe';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {AlertComponent} from './components/alert/alert.component';
import {ProcessingComponent} from './components/processing/processing.component';
import {HorizontalLineComponent} from './components/horizontal-line/horizontal-line.component';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {DashboardComponent} from './application/dashboard/dashboard.component';
import {AuthComponent} from './application/auth/auth.component';
import {HeaderComponent} from './components/header/header.component';
import {BottomNavBarComponent} from './components/bottom-nav-bar/bottom-nav-bar.component';
import {AuthGuardService} from "./services/auth-guard.service";
import {AccountProfileComponent} from './components/account-profile/account-profile.component';
import {AvatarPipe} from './pipes/avatar.pipe';
import {JobIconPipe} from './pipes/job-icon.pipe';
import {CompanyIconPipe} from './pipes/company-icon.pipe';
import {StatusIconPipe} from './pipes/status-icon.pipe';
import {AccountsOnlineComponent} from './components/accounts-online/accounts-online.component';
import {AboutComponent} from './components/about/about.component';
import {AmbassadorsComponent} from './components/ambassadors/ambassadors.component';
import {PartnersComponent} from './components/partners/partners.component';
import {SelectionsComponent} from './components/selections/selections.component';
import {ForumComponent} from './components/forum/forum.component';
import {ForumAccountComponent} from './components/forum-account/forum-account.component';
import {TimeAgoPipe} from './pipes/time-ago.pipe';
import {AddPostComponent} from './components/add-post/add-post.component';
import {MediaTypeComponent} from './components/media-type/media-type.component';
import {PostsComponent} from './components/posts/posts.component';
import {PostComponent} from './components/post/post.component';
import {PostHeaderComponent} from './components/post-header/post-header.component';
import {PostMediaComponent} from './components/post-media/post-media.component';
import {PostDescriptionComponent} from './components/post-description/post-description.component';
import {PostCommentsComponent} from './components/post-comments/post-comments.component';
import {PdfViewerModule} from "ng2-pdf-viewer";
import {NgxViewerModule} from "ngx-viewer";
import {PostCommentComponent} from './components/post-comment/post-comment.component';
import {ProfileComponent} from './application/profile/profile.component';
import {LocalStorageService} from "./services/local-storage.service";
import {EditPostComponent} from './application/edit-post/edit-post.component';
import {InfoTextComponent} from './components/info-text/info-text.component';
import {DropdownComponent} from './components/dropdown/dropdown.component';
import {TextareaComponent} from './components/textarea/textarea.component';
import {ButtonComponent} from './components/button/button.component';
import {PreviewPostMediaComponent} from './components/preview-post-media/preview-post-media.component';
import {MessagesComponent} from './application/messages/messages.component';
import {NotificationsComponent} from './application/notifications/notifications.component';
import {NetworkComponent} from './application/network/network.component';
import {SearchComponent} from './application/search/search.component';
import {InteractionDetailComponent} from './components/interaction-detail/interaction-detail.component';
import {ProfileCardComponent} from './components/profile-card/profile-card.component';
import {SuggestionsComponent} from './components/suggestions/suggestions.component';
import {IvyCarouselModule} from "angular-responsive-carousel";
import {ProfileAccountComponent} from './components/profile-account/profile-account.component';
import {YearAgoPipe} from './pipes/year-ago.pipe';
import {ImageTextComponent} from './components/image-text/image-text.component';
import {AccountDocumentsComponent} from './components/account-documents/account-documents.component';
import {AccountCurriculumComponent} from './components/account-curriculum/account-curriculum.component';
import {SearchTypesComponent} from './components/search-types/search-types.component';
import {CollapsibleTileComponent} from './components/collapsible-tile/collapsible-tile.component';
import {SearchResultsTileComponent} from './components/search-results-tile/search-results-tile.component';
import {SearchPostComponent} from './components/search-post/search-post.component';
import {AccountShelfComponent} from './components/account-shelf/account-shelf.component';
import {AccountCursusComponent} from './components/account-cursus/account-cursus.component';
import {AssistanceComponent} from './application/assistance/assistance.component';
import {SettingsComponent} from './application/settings/settings.component';
import {PostDetailsComponent} from './application/post-details/post-details.component';
import {AssistanceDashboardComponent} from './components/assistance-dashboard/assistance-dashboard.component';
import {AssistanceFaqComponent} from './components/assistance-faq/assistance-faq.component';
import {AssistanceContactUsComponent} from './components/assistance-contact-us/assistance-contact-us.component';
import {FooterComponent} from './components/footer/footer.component';
import {environment} from "../environments/environment";
import {ApiModule as AccountApi, BASE_PATH as BASE_PATH_ACCOUNT} from 'ApiModuleAccount';
import {ApiModule as PostApi, BASE_PATH as BASE_PATH_POST} from 'ApiModulePost';
import {DocumentsComponent} from './application/documents/documents.component';
import {DocumentTileComponent} from './components/document-tile/document-tile.component';
import {EditProfileComponent} from './application/edit-profile/edit-profile.component';
import {ProfileResumeComponent} from './components/profile-resume/profile-resume.component';
import {ProfileDetailsComponent} from './components/profile-details/profile-details.component';
import {ProfileTrainingsComponent} from './components/profile-trainings/profile-trainings.component';
import {ProfileJobsComponent} from './components/profile-jobs/profile-jobs.component';
import {ProfileSkillsComponent} from './components/profile-skills/profile-skills.component';
import {ProfileBiographyComponent} from './components/profile-biography/profile-biography.component';
import {LongTextDisplayComponent} from './components/long-text-display/long-text-display.component';
import {CursusTileComponent} from './components/cursus-tile/cursus-tile.component';
import {CursusComponent} from './components/cursus/cursus.component';
import {CurriculumComponent} from './components/curriculum/curriculum.component';
import {SelectionAccountComponent} from './components/selection-account/selection-account.component';
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {EditSkillComponent} from './components/edit-skill/edit-skill.component';
import {EditJobComponent} from './components/edit-job/edit-job.component';
import {EditProfileDetailsComponent} from './components/edit-profile-details/edit-profile-details.component';
import {ToggleButtonComponent} from './components/toggle-button/toggle-button.component';
import {ProfileDiplomasComponent} from './components/profile-diplomas/profile-diplomas.component';
import {EditDiplomaComponent} from './components/edit-diploma/edit-diploma.component';
import {ProfileCertificationsComponent} from './components/profile-certifications/profile-certifications.component';
import {EditCertificationComponent} from './components/edit-certification/edit-certification.component';
import {EditTrainingComponent} from './components/edit-training/edit-training.component';
import {AutocompleteInputComponent} from './components/dropdown-search/autocomplete-input.component';
import {ArrayLabelPipe} from './pipes/array-label.pipe';
import {SettingsAccountComponent} from './components/settings-account/settings-account.component';
import {SettingsSecurityComponent} from './components/settings-security/settings-security.component';
import {
  SettingsConfidentialityComponent
} from './components/settings-confidentiality/settings-confidentiality.component';
import {SettingsLocationsComponent} from './components/settings-locations/settings-locations.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastrModule} from "ngx-toastr";
import {
  ProfileOngoingTrainingsComponent
} from './components/profile-ongoing-trainings/profile-ongoing-trainings.component';
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import {YouTubePlayerModule} from "@angular/youtube-player";
import {OtherPostComponent} from './components/other-post/other-post.component';
import {PostDisplayComponent} from './application/post-display/post-display.component';
import {ProfileMobileCardComponent} from './components/profile-mobile-card/profile-mobile-card.component';
import {ButtonGradientComponent} from './components/button-gradient/button-gradient.component';
import {ButtonFollowComponent} from './components/button-follow/button-follow.component';
import {PostInteractionsComponent} from './components/post-interactions/post-interactions.component';
import {PostInteractionComponent} from './components/post-interaction/post-interaction.component';
import {NgxDocViewerModule} from "ngx-doc-viewer";
import {NgxPaginationModule} from "ngx-pagination";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {SettingsAboutComponent} from './components/settings-about/settings-about.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {EditAvatarComponent} from './components/edit-avatar/edit-avatar.component';
import {ImageCropperModule} from "ngx-image-cropper";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {StatusPipe} from './pipes/status.pipe';
import {TermsAndConditionsComponent} from './public/terms-and-conditions/terms-and-conditions.component';
import {CguComponent} from './components/cgu/cgu.component';
import {FaqsComponent} from './public/faqs/faqs.component';
import {ImageComponent} from './components/image/image.component';
import {DownloadService} from "./services/download.service";
import {AccountDetailsComponent} from './application/account-details/account-details.component';
import {CountryPipe} from './pipes/country.pipe';
import {AccountCreatedComponent} from './public/account-created/account-created.component';
import {PostDetailComponent} from './public/post-detail/post-detail.component';
import {OfflineHeaderComponent} from './components/offline-header/offline-header.component';
import {BottomSheetModule} from "./modules/bottom-sheet/bottom-sheet.module";
import {BottomSheetFiltersComponent} from "./components/bottom-sheet-filters/bottom-sheet-filters.component";
import {PasswordResetComponent} from "./public/password-reset/password-reset.component";
import {ResetPasswordComponent} from "./public/reset-password/reset-password.component";
import {AccountActivatedComponent} from "./public/account-activated/account-activated.component";
import { DashboardPostComponent } from './components/dashboard-post/dashboard-post.component';
import { SharedPostHeaderComponent } from './components/shared-post-header/shared-post-header.component';
import { GenderPipe } from './pipes/gender.pipe';
import { NotificationsService} from './services/notifications.service';
import { QuoteComponent } from './components/quote/quote.component';
import { PartnerViewComponent } from './components/partner-view/partner-view.component';
import { MoreComponent } from './application/more/more.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InputComponent,
    ImagePipe,
    AlertComponent,
    ProcessingComponent,
    HorizontalLineComponent,
    DashboardComponent,
    AuthComponent,
    HeaderComponent,
    BottomNavBarComponent,
    AccountProfileComponent,
    AvatarPipe,
    JobIconPipe,
    CompanyIconPipe,
    StatusIconPipe,
    AccountsOnlineComponent,
    AboutComponent,
    AmbassadorsComponent,
    PartnersComponent,
    SelectionsComponent,
    ForumComponent,
    ForumAccountComponent,
    TimeAgoPipe,
    AddPostComponent,
    MediaTypeComponent,
    PostsComponent,
    PostComponent,
    PostHeaderComponent,
    PostMediaComponent,
    PostDescriptionComponent,
    PostCommentsComponent,
    PostCommentComponent,
    ProfileComponent,
    EditPostComponent,
    InfoTextComponent,
    DropdownComponent,
    TextareaComponent,
    ButtonComponent,
    PreviewPostMediaComponent,
    MessagesComponent,
    NotificationsComponent,
    NetworkComponent,
    SearchComponent,
    InteractionDetailComponent,
    ProfileCardComponent,
    SuggestionsComponent,
    ProfileAccountComponent,
    YearAgoPipe,
    ImageTextComponent,
    AccountDocumentsComponent,
    AccountCurriculumComponent,
    SearchTypesComponent,
    CollapsibleTileComponent,
    SearchResultsTileComponent,
    SearchPostComponent,
    AccountShelfComponent,
    AccountCursusComponent,
    AssistanceComponent,
    SettingsComponent,
    PostDetailsComponent,
    AssistanceDashboardComponent,
    AssistanceFaqComponent,
    AssistanceContactUsComponent,
    FooterComponent,
    DocumentsComponent,
    DocumentTileComponent,
    EditProfileComponent,
    ProfileResumeComponent,
    ProfileDetailsComponent,
    ProfileTrainingsComponent,
    ProfileJobsComponent,
    ProfileSkillsComponent,
    ProfileBiographyComponent,
    LongTextDisplayComponent,
    CursusTileComponent,
    CursusComponent,
    CurriculumComponent,
    SelectionAccountComponent,
    EditSkillComponent,
    EditJobComponent,
    EditProfileDetailsComponent,
    ToggleButtonComponent,
    ProfileDiplomasComponent,
    EditDiplomaComponent,
    ProfileCertificationsComponent,
    EditCertificationComponent,
    EditTrainingComponent,
    AutocompleteInputComponent,
    ArrayLabelPipe,
    SettingsAccountComponent,
    SettingsSecurityComponent,
    SettingsConfidentialityComponent,
    SettingsLocationsComponent,
    ProfileOngoingTrainingsComponent,
    OtherPostComponent,
    PostDisplayComponent,
    ProfileMobileCardComponent,
    ButtonGradientComponent,
    ButtonFollowComponent,
    PostInteractionsComponent,
    PostInteractionComponent,
    SettingsAboutComponent,
    PaginationComponent,
    EditAvatarComponent,
    StatusPipe,
    TermsAndConditionsComponent,
    CguComponent,
    FaqsComponent,
    ImageComponent,
    AccountDetailsComponent,
    CountryPipe,
    AccountCreatedComponent,
    PostDetailComponent,
    OfflineHeaderComponent,
    BottomSheetFiltersComponent,
    PasswordResetComponent,
    AccountActivatedComponent,
    ResetPasswordComponent,
    DashboardPostComponent,
    SharedPostHeaderComponent,
    GenderPipe,
    QuoteComponent,
    PartnerViewComponent,
    MoreComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    InfiniteScrollModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PdfViewerModule,
    NgxViewerModule,
    IvyCarouselModule,
    AccountApi,
    PostApi,
    CKEditorModule,
    NgxExtendedPdfViewerModule,
    YouTubePlayerModule,
    NgxDocViewerModule,
    NgxPaginationModule,
    NgbModule,
    ImageCropperModule,
    InfiniteScrollModule,
    BottomSheetModule
  ],
  providers: [
    LocalStorageService,
    AuthGuardService,
    DownloadService,
    ImagePipe,
    AvatarPipe,
    JobIconPipe,
    CompanyIconPipe,
    StatusIconPipe,
    TimeAgoPipe,
    YearAgoPipe,
    ArrayLabelPipe,
    CountryPipe,
    GenderPipe,
    NotificationsService,
    {
      provide: BASE_PATH_ACCOUNT,
      useValue: environment.SKILLSMATES_API_BASE_PATH_ACCOUNT
    }, {
      provide: BASE_PATH_POST,
      useValue: environment.SKILLSMATES_API_BASE_PATH_POST
    },
  ],
  exports: [
    HorizontalLineComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
