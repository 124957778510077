import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {MimetypeHelper} from "../../helpers/mimetype-helper";
import {MediaHelper} from "../../helpers/media-helper";
import {PostDetailsResource} from "ApiModulePost";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'sm-post-media',
  templateUrl: './post-media.component.html',
  styleUrls: ['./post-media.component.scss']
})
export class PostMediaComponent {
  @Input() post!: PostDetailsResource;
  @Input() height: number = 300;
  @ViewChild('content') popupview !: ElementRef;
  videoId = "";
  protected readonly window = window;

  constructor(private router: Router, private modalService: NgbModal) {

  }

  ngOnInit(): void {
    const tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(tag);
  }

  setDefault() {
    this.post.thumbnail = './assets/images/publications.svg';
  }

  isImage(): boolean {
    return MimetypeHelper.isImageLink(this.post!.link!);
  }

  isPdf(): boolean {
    return MimetypeHelper.isPdfLink(this.post!.link!);
  }

  isLink(): boolean {
    return this.post.documentType?.startsWith('MEDIA_TYPE_LINK')!;
  }

  isYoutube(): boolean {
    return MediaHelper.isYoutubeLink(this.post!.link!);
  }

  isSpotify(): boolean {
    return MediaHelper.isSpotifyLink(this.post!.link!);
  }

  isOfficeDocument(): boolean {
    return MediaHelper.isOfficeDocumentLink(this.post!.link!);
  }

  isVideoFile(): boolean {
    return MimetypeHelper.isVideoLink(this.post!.link!);
  }

  getYoutubeVideoId(): string | null {
    return MediaHelper.getYoutubeVideoId(this.post!.link!);
  }

  onClickMedia(post: PostDetailsResource): void {
    this.modalService.open(this.popupview, {size: 'lg'});
  }

  onClickMediaLink(post: PostDetailsResource): void {
    window.open(post.link, '_blank');
  }
}
