<div class="skillsmates-box-shadow" [ngStyle]="{
      'background-image': 'url('+ partnerData.getPartnerPicture()+')',
      'height': '280px',
      'display':'flex',
      'border-radius': '10px',
      'flex-direction':'column',
      'background-size': 'contain',
      'background-position': 'center',
      'background-repeat': 'no-repeat'
    }" (click)="onClickOpenPartner(partnerData.url)">
</div>
