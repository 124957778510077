import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {StringHelper} from "../../helpers/string-helper";
import {Route} from "../../enums/route.enum";
import {AccountDetailsResource} from "ApiModuleAccount";
import {variables} from "../../../environments/variables";
import {LocalStorageService} from "../../services/local-storage.service";
import {BottomSheetConfig} from "../../modules/bottom-sheet/utility/bottom-sheet.interface";
import {BottomSheetPlusComponent} from "../../modules/bottom-sheet/bottom-sheet-plus/bottom-sheet-plus.component";
import {BottomSheetComponent} from "../../modules/bottom-sheet/bottom-sheet/bottom-sheet.component";


@Component({
  selector: 'sm-bottom-nav-bar',
  templateUrl: './bottom-nav-bar.component.html',
  styleUrls: ['./bottom-nav-bar.component.scss']
})
export class BottomNavBarComponent implements OnInit {
  selectedImageIcon = 'home';
  showModalPost = false;
  showModalAccount = false;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  Route = Route;

  dropdownProfileMenu = false;
  // showModalPost = false;
  // showModalAccount = false;
  searchImageIcon = 'search';
  messageImageIcon = 'message';
  plusImageIcon = 'plus-mobile';
  homeImageIcon = 'home';
  bellImageIcon = 'bell';
  networkImageIcon = 'network';

  // selectedImageIcon = 'home';

  @ViewChild('bottomSheet') bottomSheet!: BottomSheetPlusComponent;
  options!: BottomSheetConfig;

  constructor(private router: Router, private storageService: LocalStorageService) {
  }

  ngOnInit(): void {
    this.options = {
      backgroundColor: "#ffffff",
      fontColor: "#363636"
    }
  }


  getTab(): string {
    return StringHelper.urlToTab(this.router.url);
  }

  onClick(tab: string): void {
    this.selectedImageIcon = tab;
    this.showModalPost = false;
    if (tab === 'plus-mobile') {
      this.showModalAccount = !this.showModalAccount;
    }
    // this.displayPopup(tab);
  }

  onClickEditPost(): void {
    this.router.navigate([Route.EDIT_POST]);
  }

  getHomeIcon(): string {
    if (window.location.pathname.indexOf('dashboard') > 0) {
      return 'home-selected';
    } else {
      return 'home';
    }
  }

  getMessageIcon(): string {
    if (window.location.pathname.indexOf('message') > 0) {
      return 'message-selected';
    } else {
      return 'message';
    }
  }

  getNotificationIcon(): string {
    if (window.location.pathname.indexOf('notification') > 0) {
      return 'bell-selected';
    } else {
      return 'bell';
    }
  }

  getNetworkIcon(): string {
    if (window.location.pathname.indexOf('network') > 0) {
      return 'network-selected';
    } else {
      return 'network';
    }
  }

  getSearchIcon(): string {
    if (window.location.pathname.indexOf('search') > 0) {
      return 'search-selected';
    } else {
      return 'search';
    }
  }

  getPlusIcon(): string {
    if (window.location.pathname.indexOf('profile') > 0 || window.location.pathname.indexOf('setting') > 0 || window.location.pathname.indexOf('assistance') > 0) {
      return 'plus-mobile-selected';
    } else {
      return 'plus-mobile';
    }
  }

  resetImageIcon(): void {
    this.homeImageIcon = 'home';
    this.searchImageIcon = 'search';
    this.bellImageIcon = 'bell';
    this.networkImageIcon = 'network';
  }

  onClickProfileMenu(menu: string): void {
    this.showModalAccount = false;
    this.showModalPost = false;
    this.dropdownProfileMenu = false;
    this.resetImageIcon();

    switch (menu) {
      case "profile":
        this.storageService.update(variables.profile_account, this.storageService.getLoggedAccount());
        this.router.navigate([Route.PROFILE]);
        break;
      case "settings":
        break;
      case "assistance":
        break;
      case "logout":
        this.storageService.clear();
        this.router.navigate([Route.LOGIN]);
        break;
      case "others":
        this.router.navigate([Route.MORE]);
        break;
      default:
        break;
    }
  }

  openBottomSheetPlus() {
    this.bottomSheet.open();
  }
  onCloseSheetPlus(): void {
    this.bottomSheet.close();
  }

}
