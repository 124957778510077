import {Component, Input, OnInit} from '@angular/core';
import {AuthorQuote} from "../../models/authorQuote";

@Component({
  selector: 'sm-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {

  @Input() quote: AuthorQuote = {} as AuthorQuote;

  ngOnInit() {
  }

}
