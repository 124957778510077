import {Component, OnInit} from '@angular/core';
import {Alert} from "../../models/alert";
import {Router} from "@angular/router";
import {variables} from "../../../environments/variables";
import {StringHelper} from "../../helpers/string-helper";
import {LocalStorageService} from "../../services/local-storage.service";
import {Route} from "../../enums/route.enum";
import {
  AccountAuthRequest,
  AccountCreationRequest,
  AccountDetailsResource,
  AuthenticationService
} from "ApiModuleAccount";
import {PasswordError} from "../../models/passwordError";
import packageJson from "../../../../package.json";
import {ToastrService} from "ngx-toastr";
import {ValidationCondition} from "../../models/validationCondition";
import {environment} from "../../../environments/environment";

export enum PageMode {
  LOGIN, REGISTER, PASSWORD_RESET_INIT, PASSWORD_RESET_CONFIRM
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  apkUrl : string = 'https://skillsmatesmedia.s3.us-east-2.amazonaws.com/multimedia/apk/'+environment.SKILLSMATES_ENV+'/app.apk'
  currentMode: PageMode = PageMode.LOGIN
  passwordEditEmail: string = ""
  email: string = "";
  password: string = "";
  firstname: string = "";
  lastname: string = "";
  checked: boolean = false;
  showCguErrorMessage: boolean = false;
  accountAuthRequest: AccountAuthRequest = {} as AccountAuthRequest;
  accountCreationRequest: AccountCreationRequest = {} as AccountCreationRequest;
  account!: AccountDetailsResource;
  loading: boolean = false;
  alert: Alert = new Alert();
  msgErrors: PasswordError[] = [];
  errorMessageEmail: string = "Email obligatoire";
  showErrorMessageEmail: boolean = false;
  inputStatusEmail: string = "";
  errorMessagePassword: string = "Mot de passe obligatoire";
  showErrorMessagePassword: boolean = false;
  inputStatusPassword: string = "";
  errorMessageFirstname: string = "Prénom obligatoire";
  showErrorMessageFirstname: boolean = false;
  inputStatusFirstname: string = "";
  errorMessageLastname: string = "Nom obligatoire";
  showErrorMessageLastname: boolean = false;
  inputStatusLastname: string = "";
  errorMessageConfirmPassword: string = "Confirmation du mot de passe obligatoire";
  showErrorMessageConfirmPassword: boolean = false;
  inputStatusConfirmPassword: string = "";
  Route = Route;
  PageMode = PageMode;
  year: number = new Date().getFullYear();
  validations: ValidationCondition[] = []
  public version: string = packageJson.version;

  constructor(private authenticationService: AuthenticationService,
              private router: Router, private localStorageService: LocalStorageService,
              private toastrService: ToastrService) {
  }

  ngOnInit(): void {
    this.initValidation()
    this.currentMode = this.localStorageService.get(variables.page_mode);
    if (!this.currentMode) {
      this.currentMode = PageMode.LOGIN;
    }
    let account = this.localStorageService.get(variables.account_create);
    if (account) {
      this.accountCreationRequest = account;
      this.accountCreationRequest.status = undefined
      this.currentMode = PageMode.REGISTER
    }
    this.msgErrors = StringHelper.initPasswordErrors();
  }

  getEducationLabels(): string[] {
    return ["Statut(Etudiant/enseignant/professionnel)"].concat(Object.values(AccountCreationRequest.StatusEnum)
      .map(value => this.convertAccountTypeToLabel(value)))
  }

  convertAccountTypeToLabel(type: AccountCreationRequest.StatusEnum) {
    switch (type) {
      case "STUDENT":
        return 'Etudiant';
      case "PROFESSIONAL":
        return 'Professionel'
      case "TEACHER":
        return 'Enseignant'
    }
  }

  convertAccountLabelToEnum(label: string) {
    switch (label) {
      case "Etudiant":
        return AccountCreationRequest.StatusEnum.Student;
      case "Professionel":
        return AccountCreationRequest.StatusEnum.Professional
      case "Enseignant":
        return AccountCreationRequest.StatusEnum.Teacher
      default:
        return undefined
    }
  }

  getAccountType(event: string): void {
    this.accountCreationRequest.status = this.convertAccountLabelToEnum(event);
  }

  initValidation() {
    this.validations = []
    this.validations.push(new ValidationCondition(false, 'SIZE', '- Mot de passe doit contenir entre 8 et 16 caractères'))
    this.validations.push(new ValidationCondition(false, 'DIGIT', '- Mot de passe doit contenir au moins un chiffre'))
    this.validations.push(new ValidationCondition(false, 'UPPERCASE', '- Mot de passe doit contenir au moins une lettre majuscule'))
    this.validations.push(new ValidationCondition(false, 'LOWERCASE', '- Mot de passe doit contenir au moins une lettre minuscule'))
    this.validations.push(new ValidationCondition(false, 'SPECIAL', '- Mot de passe doit contenir au moins un caractère spécial'))
    this.validations.push(new ValidationCondition(false, 'SAME', '- les 2 champs doivent contenir la même valeur'))
  }

  checkSize() {
    let condition = (this.accountCreationRequest.password!.length >= 8) &&
      (this.accountCreationRequest.password!.length <= 16)
    this.updateValidation('SIZE', condition)
  }

  checkDigit() {
    let condition = /\d/.test(this.accountCreationRequest.password!)
    this.updateValidation('DIGIT', condition)
  }

  checkUpperCase() {
    let condition = /[A-Z]/.test(this.accountCreationRequest.password!)
    this.updateValidation('UPPERCASE', condition)
  }

  checkLowerCase() {
    let condition = /[a-z]/.test(this.accountCreationRequest.password!)
    this.updateValidation('LOWERCASE', condition)
  }

  checkSpecial() {
    let condition = /[^\w\s]/.test(this.accountCreationRequest.password!)
    this.updateValidation('SPECIAL', condition)
  }

  checkSame() {
    let condition = (this.accountCreationRequest.password!.length > 0) &&
      (this.accountCreationRequest.password! === this.accountCreationRequest.confirmPassword!)
    this.updateValidation('SAME', condition)
  }

  updateValidation(code: string, newValue: boolean) {
    this.validations.forEach(value => {
      if (value.code === code) {
        value.valid = newValue
      }
    })
  }

  validateInput() {
    this.checkSize()
    this.checkDigit()
    this.checkUpperCase()
    this.checkLowerCase()
    this.checkSpecial()
    this.checkSame()
  }

  validateNewPassword() {
    let isValid = this.isValid()
    this.inputStatusPassword = isValid ? 'valid' : 'invalid'
    this.showErrorMessagePassword = !isValid
    this.errorMessagePassword = isValid ? '' : this.validations.find(value => !value.valid)?.message!
    return isValid
  }

  validateConfirm() {
    let validation = this.validations.find(value => value.code === 'SAME')
    let isValid = validation?.valid
    this.inputStatusConfirmPassword = isValid === true ? 'valid' : 'invalid'
    this.showErrorMessageConfirmPassword = isValid !== true
    this.errorMessageConfirmPassword = validation?.message!
    return isValid === true
  }

  isValid() {
    let result = true;
    this.validations.forEach(value => result = result && value.valid)
    return result;
  }

  onClickLogin(): void {
    if (this.validateAccountForLogin()) {
      this.loading = true;
      this.authenticationService
        .authenticateAccount(this.accountAuthRequest!)
        .subscribe({
          next: response => {
            this.loading = false
            this.account = response.resource!;
            this.localStorageService.add(variables.logged_account, this.account);
            this.router.navigate([Route.DASHBOARD]);
          },
          error: error => {
            this.alert = {
              display: true,
              class: 'danger',
              title: 'Erreur',
              message: '  ' + error.error.errors[0].detail,
            };
            this.loading = false;
          },
          complete: () => {
            this.loading = false;
          }
        });
    } else {
      this.alert = {
        display: true,
        class: 'danger',
        title: 'Erreur',
        message: '  Les valeurs entrées sont invalides',
      };
      this.loading = false;
    }
  }

  onClickRegister(): void {
    if (this.validateAccountForRegistration()) {
      this.loading = true;
      this.authenticationService.createAuthAccount(this.accountCreationRequest)
        .subscribe({
          next: response => {
            this.loading = false;
            this.account = response.resource!;
            this.localStorageService.add(variables.logged_account, this.account);
            this.localStorageService.delete(variables.account_create);
            this.router.navigate([Route.ACCOUNT_CREATED]);
          },
          error: error => {
            this.loading = false;
            this.alert = {
              display: true,
              class: 'danger',
              title: 'Erreur',
              message: '  ' + error.error.errors[0].detail,
            };
          },
          complete: () => {
            this.loading = false;
          }
        });
    } else {
      this.loading = false;
    }
  }

  validateAccountForLogin(): boolean {
    this.accountAuthRequest.email = this.accountAuthRequest.email.toLowerCase()
    if (!StringHelper.validateEmail(this.accountAuthRequest.email)) {
      this.inputStatusEmail = "invalid";
      this.showErrorMessageEmail = true;
      this.errorMessageEmail = "Email invalide";
    } else {
      this.inputStatusEmail = "valid";
      this.showErrorMessageEmail = false;
    }

    if (this.accountAuthRequest.password === undefined || this.accountAuthRequest.password === '') {
      this.inputStatusPassword = "invalid";
      this.showErrorMessagePassword = true;
      this.errorMessagePassword = "Mot de passe requis";
    } else {
      if (this.accountAuthRequest.password && this.accountAuthRequest.password.length < 5) {
        this.inputStatusPassword = "invalid";
        this.showErrorMessagePassword = true;
        this.errorMessagePassword = "Mot de passe doit être de 5 caractéres minimum";
      } else {
        this.inputStatusPassword = "valid";
        this.showErrorMessagePassword = false;
      }
    }
    return !this.showErrorMessageEmail && !this.showErrorMessagePassword;
  }

  validateAccountForRegistration(): boolean {
    let isValid: boolean = true;
    if (this.accountCreationRequest.firstname === undefined || this.accountCreationRequest.firstname === '') {
      this.inputStatusFirstname = "invalid";
      this.showErrorMessageFirstname = true;
      this.errorMessageFirstname = "Prénom requis";
    } else {
      this.inputStatusFirstname = "valid";
      this.showErrorMessageFirstname = false;
    }
    isValid &&= !this.showErrorMessageFirstname;

    if (this.accountCreationRequest.lastname === undefined || this.accountCreationRequest.lastname === '') {
      this.inputStatusLastname = "invalid";
      this.showErrorMessageLastname = true;
      this.errorMessageLastname = "Nom requis";
    } else {
      this.inputStatusLastname = "valid";
      this.showErrorMessageLastname = false;
    }
    isValid &&= !this.showErrorMessageLastname;

    if (!StringHelper.validateEmail(this.accountCreationRequest.email)) {
      this.inputStatusEmail = "invalid";
      this.showErrorMessageEmail = true;
      this.errorMessageEmail = "Email invalide";
    } else {
      this.inputStatusEmail = "valid";
      this.showErrorMessageEmail = false;
    }
    isValid &&= !this.showErrorMessageEmail;
    isValid &&= this.validateNewPassword()
    isValid &&= this.validateConfirm()

    this.showCguErrorMessage = !this.checked;
    isValid &&= !this.showCguErrorMessage;

    return isValid;
  }

  onClickFAQ(): void {
    localStorage.setItem(variables.faq, variables.faq);
    this.router.navigate([Route.FAQ])
  }

  onClickAbout(): void {
    localStorage.setItem(variables.setting_tab, 'about');
    this.router.navigate([Route.SETTINGS])
  }

  onClickCGU(): void {
    this.localStorageService.add(variables.account_create, this.accountCreationRequest);
    this.localStorageService.add(variables.url, Route.LOGIN);
    this.localStorageService.add(variables.terms_and_conditions, 'true');
    this.router.navigate([Route.TERMS_AND_CONDITIONS])
  }

  changeMode(mode: PageMode) {
    this.currentMode = mode
    if (mode === PageMode.REGISTER) {
      this.initValidation()
      this.accountCreationRequest.status = undefined
    }
  }

  getEmailForPasswordReset(e: any): void {
    this.passwordEditEmail = e.toString()
  }

  getEmail(e: any): void {
    if (this.accountAuthRequest) {
      this.accountAuthRequest.email = e.toString().toLowerCase();
      this.accountCreationRequest.email = e.toString().toLowerCase();
    }
  }

  doInitPasswordReset() {
    if (this.isEmailValid(this.passwordEditEmail, this.showErrorMessageEmail, this.errorMessageEmail)) {
      this.loading = true
      this.authenticationService.initPasswordReset({email: this.passwordEditEmail}).subscribe({
        next: response => {
          this.toastrService.success("Un e-mail vous a été envoyé.")
          this.loading = false
          this.passwordEditEmail = ""
          this.showErrorMessageEmail = false
          this.errorMessageEmail = ""
        },
        error: error => {
          this.loading = false
          this.toastrService.error(error.error.errors[0].detail )
          console.log(error.error.errors[0].detail)
        },
        complete: () => {
          this.loading = false;
        }
      })
    } else {
      this.alert = {
        display: true,
        class: 'danger',
        title: 'Erreur',
        message: 'Formulaire invalide',
      };
      this.loading = false;
    }
  }

  isEmailValid(email: string, showError: boolean, errorMsg: string): boolean {
    if (!StringHelper.validateEmail(email)) {
      this.inputStatusEmail = "invalid"
      showError = true;
      errorMsg = "Email invalide";
    } else {
      this.inputStatusEmail = "valid"
      showError = false;
      errorMsg = ""
    }
    return !showError
  }

  getPassword(e: any): void {
    if (this.accountAuthRequest) {
      this.accountAuthRequest.password = e
      this.accountCreationRequest.password = e;
      if (this.currentMode == PageMode.REGISTER)
        this.validateInput()
    }
  }

  getConfirmPassword(e: any): void {
    if (this.accountAuthRequest) {
      this.accountCreationRequest.confirmPassword = e;
      this.validateInput()
    }
  }

  getFirstname(e: any): void {
    if (this.accountAuthRequest) {
      this.accountCreationRequest.firstname = e.toString();
    }
  }

  getLastname(e: any): void {
    if (this.accountAuthRequest) {
      this.accountCreationRequest.lastname = e.toString();
    }
  }
}
