import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./public/login/login.component";
import {AuthComponent} from "./application/auth/auth.component";
import {DashboardComponent} from "./application/dashboard/dashboard.component";
import {AuthGuardService} from "./services/auth-guard.service";
import {ProfileComponent} from "./application/profile/profile.component";
import {EditPostComponent} from "./application/edit-post/edit-post.component";
import {MessagesComponent} from "./application/messages/messages.component";
import {NotificationsComponent} from "./application/notifications/notifications.component";
import {NetworkComponent} from "./application/network/network.component";
import {SearchComponent} from "./application/search/search.component";
import {AssistanceComponent} from "./application/assistance/assistance.component";
import {SettingsComponent} from "./application/settings/settings.component";
import {DocumentsComponent} from "./application/documents/documents.component";
import {EditProfileComponent} from "./application/edit-profile/edit-profile.component";
import {PostDisplayComponent} from "./application/post-display/post-display.component";
import {TermsAndConditionsComponent} from "./public/terms-and-conditions/terms-and-conditions.component";
import {FaqsComponent} from "./public/faqs/faqs.component";
import {AccountDetailsComponent} from "./application/account-details/account-details.component";
import {AccountCreatedComponent} from "./public/account-created/account-created.component";
import {PostDetailComponent} from "./public/post-detail/post-detail.component";
import {PostDetailsComponent} from "./application/post-details/post-details.component";
import {ResetPasswordComponent} from "./public/reset-password/reset-password.component";
import {PasswordResetComponent} from "./public/password-reset/password-reset.component";
import {AccountActivatedComponent} from "./public/account-activated/account-activated.component";
import {MoreComponent} from "./application/more/more.component";

const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'terms-and-conditions', component: TermsAndConditionsComponent},
  {path: 'faq', component: FaqsComponent},
  {path: 'account-details', component: AccountDetailsComponent},
  {path: 'account-created', component: AccountCreatedComponent},
  {path: 'account-activated', component: AccountActivatedComponent},
  {path: 'password-reset', component: PasswordResetComponent},
  {path: 'post-detail', component: PostDetailComponent},
  {path: 'reset-password/:id', component: ResetPasswordComponent},
  {path: 'settings', component: SettingsComponent},
  {
    path: '', component: AuthComponent, canActivate: [AuthGuardService], children: [
      {path: 'dashboard', component: DashboardComponent},
      {path: 'profile', component: ProfileComponent},
      {path: 'edit-post', component: EditPostComponent},
      {path: 'messages', component: MessagesComponent},
      {path: 'notifications', component: NotificationsComponent},
      {path: 'network', component: NetworkComponent},
      {path: 'search', component: SearchComponent},
      {path: 'assistance', component: AssistanceComponent},
      {path: 'documents', component: DocumentsComponent},
      {path: 'edit-profile', component: EditProfileComponent},
      {path: 'post', component: PostDisplayComponent},
      {path: 'post-details', component: PostDetailsComponent},
      {path: 'more', component: MoreComponent},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
