import {Mimetype} from "../models/mimetype";


export class MimetypeHelper {

  static mimetypes: Mimetype[] = [
    {extension: 'audio', type: 'audio/'},
    {extension: 'image', type: 'image/'},
    {extension: 'video', type: 'video/'},
    {extension: 'pdf', type: 'application/pdf'},
    {extension: 'txt', type: 'text/'},
    {extension: 'doc', type: 'application/msword'},
    {extension: 'xls', type: 'application/vnd.ms-excel'},
    {extension: 'ppt', type: 'application/vnd.ms-powerpoint'},
    {extension: 'mdb', type: 'application/vnd.ms-access'},
    {extension: 'docx', type: 'application/vnd.openxmlformats-officedocument'},
  ];

  static isMimetypeAllowed(mimetype: string): boolean {
    let allowed = false;
    MimetypeHelper.mimetypes.forEach(elt => {
      if (mimetype.startsWith(elt.type!)) {
        allowed = true;
      }
    });
    return allowed;
  }

  static isAudioFile(mimetype: string): boolean {
    return mimetype != undefined && (mimetype.startsWith('audio/'));
  }

  static isImageFile(mimetype: string): boolean {
    return mimetype != undefined && (mimetype.startsWith('image/'));
  }

  static isVideoFile(mimetype: string): boolean {
    return mimetype != undefined && (mimetype.startsWith('video/'));
  }

  static isWordFile(mimetype: string): boolean {
    return mimetype != undefined && (mimetype.startsWith('application/msword') || mimetype.startsWith('application/vnd.openxmlformats-officedocument.wordprocessingml'));
  }

  static isPowerPointFile(mimetype: string): boolean {
    return mimetype != undefined && (mimetype.startsWith('application/vnd.ms-powerpoint') || mimetype.startsWith('application/vnd.openxmlformats-officedocument.presentationml'));
  }

  static isExcelFile(mimetype: string): boolean {
    return mimetype != undefined && (mimetype.startsWith('application/vnd.ms-excel') || mimetype.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml'));
  }

  static isAccessFile(mimetype: string): boolean {
    return mimetype != undefined && (mimetype.startsWith('application/vnd.ms-access'));
  }

  static isPdfFile(mimetype: string): boolean {
    return mimetype != undefined && (mimetype.startsWith('application/pdf'));
  }


  static isAudioLink(mimetype: string): boolean {
    return mimetype != undefined && (mimetype.startsWith('audio/'));
  }

  static isImageLink(url: string): boolean {
    return url != undefined && (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }

  static isVideoLink(url: string): boolean {
    return url != undefined && (url.match(/\.(mp4|avi|mov|wmv|flv|webm|mkv|m4v)$/) != null);
  }

  static isWordLink(url: string): boolean {
    const fileExtension = url.split('.').pop()?.toLowerCase();
    return fileExtension === 'docx' || fileExtension === 'doc';
  }

  static isPowerPointLink(url: string): boolean {
    const fileExtension = url.split('.').pop()?.toLowerCase();
    return fileExtension === 'pptx' || fileExtension === 'ppt';
  }

  static isExcelLink(url: string): boolean {
    const fileExtension = url.split('.').pop()?.toLowerCase();
    return fileExtension === 'xlsx' || fileExtension === 'xls';
  }

  static isAccessLink(mimetype: string): boolean {
    return mimetype != undefined && (mimetype.startsWith('application/vnd.ms-access'));
  }

  static isPdfLink(url: string): boolean {
    return url != undefined && (url.match(/\.(pdf)$/i) != null);
  }

  static isOfficeDocumentUrl(url: string): boolean {
    const fileExtension = url.split('.').pop()?.toLowerCase(); // Conversion en minuscules
    const officeExtensions = [
      // Microsoft Office extensions
      'doc', 'docx', 'dot', 'dotx', 'docm', 'dotm',          // Word
      'xls', 'xlsx', 'xlsm', 'xlt', 'xltx', 'xltm', 'xlsb',  // Excel
      'ppt', 'pptx', 'pptm', 'pot', 'potx', 'potm', 'pps', 'ppsx', 'ppsm', // PowerPoint
      'mdb', 'accdb', 'mde', 'accde',                        // Access
      'pst', 'ost', 'msg',                                   // Outlook
      'vsd', 'vsdx', 'vsdm',                                 // Visio
      'pub',                                                 // Publisher
      'one',                                                 // OneNote
      'xps',                                                 // XPS (Office-generated)

      // LibreOffice extensions
      'odt', 'ott', 'fodt',                                  // Writer (LibreOffice equivalent to Word)
      'ods', 'ots', 'fods',                                  // Calc (LibreOffice equivalent to Excel)
      'odp', 'otp', 'fodp',                                  // Impress (LibreOffice equivalent to PowerPoint)
      'odg', 'otg', 'fodg',                                  // Draw (LibreOffice drawing)
      'odb',                                                 // Base (LibreOffice database)
      'odf'                                                  // Math (LibreOffice formulas)
    ];
    return officeExtensions.includes(fileExtension || '');
  }
}
